import MuiBox, { type BoxProps as MuiBoxProps } from '@mui/material/Box';
import { forwardRef } from 'react';
export interface BoxProps extends MuiBoxProps {
  'aria-label'?: string;
  'data-test-id'?: string;
  ref?: React.Ref<HTMLElement>;
}
export const Box = forwardRef<HTMLElement, BoxProps>(({
  children,
  ...rest
}, ref) => {
  return <MuiBox ref={ref} {...rest}>
      {children}
    </MuiBox>;
});